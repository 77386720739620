/* You can add global styles to this file, and also import other style files */

/* .p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  text-decoration: none;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none !important;
}

.p-accordion .p-accordion-content {
  padding: 5px !important;
} */

label{
  font-weight: bold;
}

.referral-form-labels {
  font-size: var(--font-14);
  color: var(--input-label);
}

.p-field > label {
  display: block;
  margin-top: 10px;
  font-size: var(--base-font-size);
  color: var(--btn);
  margin-bottom: 2px;
    /* color: var(--btn); */
}

.p-accordion .p-accordion-header-link {
  color: #111111; /* Dark color */
  text-decoration: none; /* Remove underline */
}

.p-accordion .p-accordion-header:hover {
  background-color: #e0e0e0; /* Slightly darker on hover */
}

.p-accordion .p-accordion-toggle {
  margin-right: 0.5rem; /* Space between toggle icon and header text */
}

.p-accordion .pi {
  font-size: 1.7rem; /* Equal-sized icons */
  vertical-align: middle; /* Align icons with text */
}

.p-accordion .p-text-bold {
  margin: 0; 
}
.fa.fa-instagram {
    color: transparent;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background-clip: text;
    -webkit-background-clip: text;
  }

.base{
    /* background-image: url('your-background-image.jpg');  /* Commented out background image */
  /* background: linear-gradient(to bottom, #0e0646, #ff0000); */
    /* background: linear-gradient(to bottom, #0e0646, #00593d); */
}

.main-content{
  color: #fff;
  /* background: linear-gradient(to left,#fff8f8, #ded7d7, #ffffff); */
  /* background: linear-gradient(to bottom, #0e0646, #00593d);  */
  /* background: linear-gradient(to left, #0e0646, #ff0000); */
}


